import React, { useEffect, useState } from 'react';
import { fetchIdeasFromJira } from '../services/jiraService';
import IdeaItem from './IdeaItem';

const IdeaList = () => {
  const [ideas, setIdeas] = useState([]);
  const [filter, setFilter] = useState('Tous');
  const [sortType, setSortType] = useState('default');

  useEffect(() => {
    const loadIdeas = async () => {
      try {
        const data = await fetchIdeasFromJira();
        
        // Ajouter les votes stockés localement à chaque idée
        const ideasWithVotes = data.map(idea => {
          const storedVotes = JSON.parse(localStorage.getItem(`votes-${idea.id}`)) || { up: 0, down: 0 };
          return { ...idea, votesUp: storedVotes.up, votesDown: storedVotes.down };
        });

        setIdeas(ideasWithVotes);
      } catch (error) {
        console.error('Erreur lors du chargement des idées:', error);
      }
    };

    loadIdeas();
  }, []);

  // Fonction de tri des idées
  const sortedIdeas = () => {
    let sorted = [...ideas];

    if (sortType === 'upvotes') {
      sorted = sorted.sort((a, b) => b.votesUp - a.votesUp);  // Tri par votes positifs décroissants
    } else if (sortType === 'downvotes') {
      sorted = sorted.sort((a, b) => b.votesDown - a.votesDown);  // Tri par votes négatifs décroissants
    }

    return filter === 'Tous'
      ? sorted
      : sorted.filter(idea => idea.status.trim().toLowerCase() === filter.trim().toLowerCase());
  };

  return (
    <div>
      <h2>📄 Influence du produit</h2>
      <p>Donnez votre avis sur l'évolution du produit et votez pour les idées qui vous tiennent à cœur.</p>
      
      <div style={{ display: 'flex', gap: '20px', marginBottom: '20px' }}>
        <select onChange={(e) => setFilter(e.target.value)} value={filter}>
          <option value="Tous">Tous</option>
          <option value="Maintenant">Maintenant</option>
          <option value="Ensuite">Ensuite</option>
          <option value="Plus tard">Plus tard</option>
          <option value="Non acceptée">Non acceptée</option>
        </select>

        <select onChange={(e) => setSortType(e.target.value)} value={sortType}>
          <option value="default">Tri par défaut</option>
          <option value="upvotes">Les plus populaires (👍)</option>
          <option value="downvotes">Les moins populaires (👎)</option>
        </select>
      </div>

      <div>
        {sortedIdeas().length > 0 ? (
          sortedIdeas().map(idea => <IdeaItem key={idea.id} idea={idea} />)
        ) : (
          <p>Aucune idée disponible.</p>
        )}
      </div>
    </div>
  );
};

export default IdeaList;
