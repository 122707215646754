import axios from 'axios';

const apiBaseUrl = 'https://jirapi.be-lifer.com/api';

/**
 * Fonction pour récupérer les idées depuis notre serveur proxy (et non Jira directement)
 */
export const fetchIdeasFromJira = async () => {
  try {
    const response = await axios.get(`${apiBaseUrl}/ideas`);
    return response.data;
  } catch (error) {
    console.error('Erreur lors de la récupération des idées:', error.response?.data || error.message);
    throw error;
  }
};

/**
 * Fonction pour soumettre une nouvelle idée à Jira via le proxy
 */
export const submitIdeaToJira = async (idea) => {
  try {
    const response = await axios.post(`${apiBaseUrl}/submit-idea`, {
      title: idea.title,
      description: idea.description,
    });

    console.log('Idée ajoutée avec succès:', response.data);
    return response.data;
  } catch (error) {
    console.error('Erreur lors de la soumission à Jira:', error.response?.data || error.message);
    throw error;
  }
};

/**
 * Fonction pour voter sur une idée
 */
export const voteIdeaInJira = async (issueId, voteType) => {
  try {
    const impactValue = voteType === 'up' ? 5 : 1;
    const response = await axios.post(`${apiBaseUrl}/vote-idea`, {
      issueId,
      impactValue,
    });

    console.log('Vote enregistré avec succès:', response.data);
    return response.data;
  } catch (error) {
    console.error('Erreur lors de l\'enregistrement du vote:', error.response?.data || error.message);
    throw error;
  }
};
