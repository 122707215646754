import React, { useEffect, useState } from 'react';
import { fetchIdeasFromJira, submitIdeaToJira } from '../services/jiraService';
import IdeaList from '../Components/IdeaList';
import '../Style/Roadmap.css';

const Roadmap = () => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [ideas, setIdeas] = useState([]);
  const [message, setMessage] = useState('');
  

  useEffect(() => {
    const loadIdeas = async () => {
      try {
        const data = await fetchIdeasFromJira();
        setIdeas(data);
      } catch (error) {
        setMessage("Erreur lors du chargement des idées.");
      }
    };
    loadIdeas();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage('');

    if (!title || !description) {
      setMessage('Veuillez remplir tous les champs.');
      return;
    }

    try {
      await submitIdeaToJira({
        title,
        description,
      });
      setMessage('✅ Votre idée a été soumise avec succès !');
      setTitle('');
      setDescription('');
    } catch (error) {
      setMessage('❌ Une erreur est survenue lors de la soumission de votre idée.');
    }
};

  return (
    <div className="roadmap-container">
      <h1>Façonnez l'avenir de Life avec nous</h1>
      <p> Life est conçu pour vous et avec vous. Proposez vos idées, votez pour celles que vous souhaitez voir en priorité et participez à la co-création de l’application idéale pour gérer votre quotidien.</p>

      <div className="idea-form-container">
        <h2>💡 Proposez votre idée</h2>
        <form onSubmit={handleSubmit} className="idea-form">
          <input
            type="text"
            placeholder="Titre de l'idée"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className="idea-input"
          />
          <textarea
            placeholder="Décrivez votre idée..."
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="idea-textarea"
          />
          <button type="submit" className="idea-submit-btn">Je propose une idée</button>
        </form>
        {message && <p className="idea-message">{message}</p>}
      </div>
      <IdeaList />
    </div>
  );
};

export default Roadmap;
