import React, { useState } from 'react';
import { voteIdeaInJira } from '../services/jiraService';

// Définition des couleurs pour les statuts
const statusColors = {
  'Maintenant': '#6BCBA0',  // Vert
  'Ensuite': '#F9E07F',     // Jaune
  'Plus tard': '#F28B82',   // Rouge
  'Non acceptée': '#AEB3BB' // Gris
};

const IdeaItem = ({ idea }) => {
  const initialVotes = JSON.parse(localStorage.getItem(`votes-${idea.id}`)) || { up: 0, down: 0 };
  const [votes, setVotes] = useState(initialVotes);

  const handleVote = (voteType) => {
    const updatedVotes = { ...votes, [voteType]: votes[voteType] + 1 };
    setVotes(updatedVotes);
    localStorage.setItem(`votes-${idea.id}`, JSON.stringify(updatedVotes));
  };

  // Fonction pour récupérer l'adresse IP utilisateur
  const getUserIp = async () => {
    try {
      const response = await fetch('https://api64.ipify.org?format=json');
      const data = await response.json();
      return data.ip;
    } catch (error) {
      console.error("Erreur lors de la récupération de l'adresse IP:", error);
      return null;
    }
  };

  return (
    <div className="idea-card">
      <h3>{idea.title}</h3>
      <p>{idea.description}</p>
      {/* <p><strong>Status:</strong> {idea.status}</p> */}
      <p><strong>Note:</strong> 👍 {votes.up} 👎 {votes.down}</p>
      <div className="vote-buttons">
        <button onClick={() => handleVote('up')} className="vote-up">👍</button>
        <button onClick={() => handleVote('down')} className="vote-down">👎</button>
      </div>
      <div className="idea-footer">
        <span 
          className="idea-status" 
          style={{ 
            backgroundColor: statusColors[idea.status] || '#ccc', 
            padding: '5px 10px', 
            borderRadius: '5px', 
            color: idea.status === 'Plus tard' ? '#fff' : '#000' 
          }}
        >
          {idea.status || 'Non spécifié'}
        </span>
      </div>
    </div>
  );
};

export default IdeaItem;
